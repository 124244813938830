.mpe-editor-shell {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.mpe-editor-shell.mpe-is-editable {
  border-radius: 8px;
  border: 1px solid #e0e5ee;
  background-color: #fbfcfd;
}

.mpe-editor-shell .mpe-editor-container {
  position: relative;
  display: block;
  height: calc(100% - 44px);
}

.mpe-full-height {
  height: 100% !important;
}

.mpe-editor {
  height: 100%;
}

.mpe-editor.mpe-is-editable {
  overflow-y: auto;
  height: calc(100% - 20px);
  min-height: 300px;
  max-height: 500px;
  padding: 20px;
}

.mpe-editor-placeholder {
  font-size: 16px;
  color: #999;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 20px;
  left: 20px;
  right: 20px;
  user-select: none;
  white-space: nowrap;
  display: inline-block;
  pointer-events: none;
}

.mpe-no-outline {
  outline: 0;
}

.mpe-dropdown {
  z-index: 10;
  display: block;
  position: fixed;
  box-shadow:
    0 12px 28px 0 rgba(0, 0, 0, 0.2),
    0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  min-height: 40px;
  background-color: #fff;
}

.mpe-dropdown .mpe-item {
  margin: 0 8px 0 8px;
  padding: 8px;
  color: #050505;
  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 8px;
  border: 0;
  max-width: 250px;
  min-width: 100px;
}

.mpe-dropdown .mpe-item.mpe-fontsize-item,
.mpe-dropdown .mpe-item.mpe-fontsize-item .mpe-text {
  min-width: unset;
}

.mpe-dropdown .mpe-item .mpe-active {
  display: flex;
  width: 20px;
  height: 20px;
  background-size: contain;
}

.mpe-dropdown .mpe-item:first-child {
  margin-top: 8px;
}

.mpe-dropdown .mpe-item:last-child {
  margin-bottom: 8px;
}

.mpe-dropdown .mpe-item:hover {
  background-color: #eee;
}

.mpe-dropdown .mpe-item .mpe-text {
  display: flex;
  line-height: 20px;
  flex-grow: 1;
  min-width: 150px;
}

.mpe-dropdown .mpe-item .mpe-icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 12px;
  line-height: 16px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.mpe-dropdown .mpe-divider {
  width: auto;
  background-color: #eee;
  margin: 4px 8px;
  height: 1px;
}
