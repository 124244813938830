.mpe-image-control-wrapper--resizing {
  touch-action: none;
}

.mpe-loader {
  width: 20px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #1a76d2;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}

.mpe-image-overlay {
  pointer-events: none;
  position: absolute;
  min-width: 200px;
  min-height: 100px;
  width: 100%;
  height: calc(100% - 4px); /* fix unknown height added to img parent */
  top: 0;
  left: 0;
}

.mpe-bg-transparent-white {
  background-color: rgba(255, 255, 255, 0.7);
}

.mpe-bg-transparent-red {
  background-color: rgba(123, 5, 5, 0.6);
}

.mpe-image-overlay > .mpe-image-overlay-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 100%;
  gap: 10px;
  font-size: 20px;
}

.mpe-font-white {
  color: white;
}

.mpe-no-image-placeholder::before {
  content: " ";
  display: block;
  position: absolute;
  height: 406px;
  width: 329px;
  background-image: url(../../images/no-image-placeholder.svg);
}
