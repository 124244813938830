.mpe-input-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.mpe-input-label {
  display: flex;
  flex: 1;
  color: #666;
}

.mpe-input-input {
  display: flex;
  flex: 4;
  border: 1px solid #999;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 16px;
  border-radius: 5px;
  min-width: 0;
}
