.mpe-toolbar {
  height: 44px;
  background-color: #fbfcfd;
  border-bottom: 1px solid #e0e5ee;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 5px;
}

.mpe-toolbar button.mpe-toolbar-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 10px;
  padding: 8px;
  cursor: pointer;
  vertical-align: middle;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
}

.mpe-toolbar button.mpe-toolbar-item:disabled {
  cursor: not-allowed;
}

.mpe-toolbar button.mpe-toolbar-item.mpe-spaced {
  margin-right: 2px;
}

.mpe-toolbar button.mpe-toolbar-item i.mpe-format {
  background-size: contain;
  display: inline-block;
  height: 18px;
  width: 18px;
  vertical-align: -0.25em;
  display: flex;
  opacity: 0.6;
}

.mpe-toolbar button.mpe-toolbar-item:disabled .mpe-icon,
.mpe-toolbar button.mpe-toolbar-item:disabled .mpe-text,
.mpe-toolbar button.mpe-toolbar-item:disabled i.mpe-format,
.mpe-toolbar button.mpe-toolbar-item:disabled .mpe-chevron-down {
  opacity: 0.2;
}

.mpe-toolbar button.mpe-toolbar-item.mpe-active {
  background-color: rgba(223, 232, 250, 0.3);
}

.mpe-toolbar button.mpe-toolbar-item.mpe-active i {
  opacity: 1;
}

.mpe-toolbar .mpe-toolbar-item:hover:not([disabled]) {
  background-color: #eee;
}

.mpe-toolbar .mpe-toolbar-item.mpe-font-family .mpe-text {
  display: block;
  max-width: 40px;
}

.mpe-toolbar .mpe-code-language {
  width: 150px;
}

.mpe-toolbar .mpe-toolbar-item .mpe-text {
  display: flex;
  line-height: 20px;
  vertical-align: middle;
  font-size: 14px;
  color: #777;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 20px;
  text-align: left;
  padding-right: 10px;
}

.mpe-toolbar .mpe-toolbar-item .mpe-icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 8px;
  line-height: 16px;
  background-size: contain;
  opacity: 0.6;
}

.mpe-toolbar .mpe-divider {
  width: 1px;
  background-color: #eee;
  margin: 0 4px;
  height: 70%;
}

.mpe-toolbar i.mpe-undo {
  background-image: url(../images/icons/arrow-counterclockwise.svg);
}

.mpe-toolbar i.mpe-redo {
  background-image: url(../images/icons/arrow-clockwise.svg);
}

.mpe-toolbar i.mpe-bold {
  background-image: url(../images/icons/type-bold.svg);
}

i.mpe-italic {
  background-image: url(../images/icons/type-italic.svg);
}

i.mpe-underline {
  background-image: url(../images/icons/type-underline.svg);
}

.mpe-icon.mpe-font-color {
  background-image: url(../images/icons/font-color.svg);
}

i.mpe-chevron-down {
  background-color: transparent;
  background-size: contain;
  display: inline-block;
  height: 8px;
  width: 8px;
  background-image: url(../images/icons/chevron-down.svg);
}

.mpe-icon.mpe-left-align,
i.mpe-left-align {
  background-image: url(../images/icons/text-left.svg);
}

i.mpe-center-align {
  background-image: url(../images/icons/text-center.svg);
}

i.mpe-right-align {
  background-image: url(../images/icons/text-right.svg);
}

i.mpe-justify-align {
  background-image: url(../images/icons/justify.svg);
}

i.mpe-indent {
  background-image: url(../images/icons/indent.svg);
}

i.mpe-outdent {
  background-image: url(../images/icons/outdent.svg);
}

i.mpe-strikethrough {
  background-image: url(../images/icons/type-strikethrough.svg);
}

i.mpe-subscript {
  background-image: url(../images/icons/type-subscript.svg);
}

i.mpe-superscript {
  background-image: url(../images/icons/type-superscript.svg);
}

i.mpe-clear {
  background-image: url(../images/icons/trash.svg);
}

i.mpe-link {
  background-image: url(../images/icons/link.svg);
}

.mpe-icon.mpe-paragraph {
  background-image: url(../images/icons/text-paragraph.svg);
}

.mpe-icon.mpe-h1 {
  background-image: url(../images/icons/type-h1.svg);
}

.mpe-icon.mpe-h2 {
  background-image: url(../images/icons/type-h2.svg);
}

.mpe-icon.mpe-h3 {
  background-image: url(../images/icons/type-h3.svg);
}

.mpe-icon.mpe-h4 {
  background-image: url(../images/icons/type-h4.svg);
}

.mpe-icon.mpe-h5 {
  background-image: url(../images/icons/type-h5.svg);
}

.mpe-icon.mpe-h6 {
  background-image: url(../images/icons/type-h6.svg);
}

.mpe-icon.mpe-bullet-list,
.mpe-icon.mpe-bullet {
  background-image: url(../images/icons/list-ul.svg);
}

.mpe-icon.mpe-numbered-list,
.mpe-icon.mpe-number {
  background-image: url(../images/icons/list-ol.svg);
}

.mpe-icon.mpe-quote {
  background-image: url(../images/icons/chat-square-quote.svg);
}

.mpe-icon.mpe-dropdown-more {
  background-image: url(../images/icons/dropdown-more.svg);
}

.mpe-icon.mpe-plus {
  background-image: url(../images/icons/plus.svg);
}

.mpe-icon.mpe-youtube {
  background-image: url(../images/icons/youtube.svg);
}

button.mpe-item i {
  opacity: 0.6;
}

button.mpe-item.mpe-dropdown-item-active {
  background-color: rgba(223, 232, 250, 0.3);
}

button.mpe-item.mpe-dropdown-item-active i {
  opacity: 1;
}

.mpe-button {
  text-align: center;
  padding: 10px 30px;
  height: 40px;
  border-radius: 8px;
  border-width: 0px;
  cursor: pointer;
  font-size: 18px;
  background-color: #f0f0f0;
}

.mpe-typeahead-popover {
  background: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  position: fixed;
}

.mpe-typeahead-popover ul {
  padding: 0;
  list-style: none;
  margin: 0;
  border-radius: 8px;
  max-height: 200px;
  overflow-y: scroll;
}

.mpe-typeahead-popover ul::-webkit-scrollbar {
  display: none;
}

.mpe-typeahead-popover ul {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.mpe-typeahead-popover ul li {
  margin: 0;
  min-width: 180px;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  border-radius: 8px;
}

.mpe-typeahead-popover ul li.selected {
  background: #eee;
}

.mpe-typeahead-popover li {
  margin: 0 8px 0 8px;
  padding: 8px;
  color: #050505;
  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  background-color: #fff;
  border-radius: 8px;
  border: 0;
}

.mpe-typeahead-popover li.active {
  display: flex;
  width: 20px;
  height: 20px;
  background-size: contain;
}

.mpe-typeahead-popover li:first-child {
  border-radius: 8px 8px 0px 0px;
}

.mpe-typeahead-popover li:last-child {
  border-radius: 0px 0px 8px 8px;
}

.mpe-typeahead-popover li:hover {
  background-color: #eee;
}

.mpe-typeahead-popover li .text {
  display: flex;
  line-height: 20px;
  flex-grow: 1;
  min-width: 150px;
}

.mpe-typeahead-popover li .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 8px;
  line-height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.mpe-auto-embed-menu {
  width: 150px;
}
