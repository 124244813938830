.mpe-theme-textBold {
  font-weight: bold;
}

.mpe-theme-textItalic {
  font-style: italic;
}

.mpe-theme-textStrikethrough {
  text-decoration: line-through;
}

.mpe-theme-textUnderline {
  text-decoration: underline;
}

.mpe-theme-textUnderlineStrikethrough {
  text-decoration: underline line-through;
}

.mpe-base-font-size {
  font-size: 16px !important;
}

.mpe-theme-paragraph {
  margin: 0 !important;
  font-size: 1em !important;
  font-weight: normal !important;
}

.mpe-theme-h1 {
  font-size: 2.125em !important;
  margin: 0 !important;
  font-weight: normal !important;
}

.mpe-theme-h2 {
  font-size: 1.6875em !important;
  margin: 0 !important;
  font-weight: normal !important;
}

.mpe-theme-h3 {
  font-size: 1.375em !important;
  margin: 0 !important;
  font-weight: normal !important;
}

.mpe-theme-h4 {
  font-size: 1em !important;
  margin: 0 !important;
  font-weight: normal !important;
}

.mpe-theme-h5 {
  font-size: 0.875em !important;
  margin: 0 !important;
  font-weight: normal !important;
}

.mpe-theme-h6 {
  font-size: 0.75em !important;
  margin: 0 !important;
  font-weight: normal !important;
}

.mpe-theme-quote {
  margin: 0 !important;
  margin-left: 20px !important;
  margin-bottom: 10px !important;
  font-size: 0.9375em !important;
  color: rgb(101, 103, 107);
  border-left-color: rgb(206, 208, 212);
  border-left-width: 4px;
  border-left-style: solid;
  padding-left: 16px;
}

.mpe-theme-super-script {
  font-size: 0.8em !important;
  vertical-align: text-top;
}

.mpe-theme-sup-script {
  font-size: 0.8em !important;
  vertical-align: text-bottom;
}

.mpe-theme-ol,
.mpe-theme-ul {
  padding-inline-start: 40px;
}

.mpe-embed-block {
  user-select: none;
}

.mpe-embed-blockFocus {
  outline: 2px solid rgb(60, 132, 244);
}

.mpe-link {
  color: blue;
  text-decoration: underline;
}

.mpe-link:visited {
  color: #681da8;
}
