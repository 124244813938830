i.mpe-image {
  background-image: url(../../images/icons/file-image.svg);
}

.mpe-editor-shell span.mpe-theme-image {
  cursor: default;
  display: inline-block;
  position: relative;
  user-select: none;
}

.mpe-editor-shell .mpe-theme-image img {
  max-width: 100%;
  cursor: default;
}

.mpe-editor-shell .mpe-theme-image img.mpe-focused {
  outline: 2px solid rgb(60, 132, 244);
  user-select: none;
}

.mpe-editor-shell .mpe-theme-image img.mpe-focused.mpe-draggable {
  cursor: grab;
}

.mpe-editor-shell .mpe-theme-image img.mpe-focused.mpe-draggable:active {
  cursor: grabbing;
}

.mpe-editor-shell .mpe-theme-image .mpe-image-resizer {
  display: block;
  width: 7px;
  height: 7px;
  position: absolute;
  background-color: rgb(60, 132, 244);
  border: 1px solid #fff;
}

.mpe-editor-shell .mpe-theme-image .mpe-image-resizer.mpe-image-resizer-n {
  top: -6px;
  left: 48%;
  cursor: n-resize;
}

.mpe-editor-shell .mpe-theme-image .mpe-image-resizer.mpe-image-resizer-ne {
  top: -6px;
  right: -6px;
  cursor: ne-resize;
}

.mpe-editor-shell .mpe-theme-image .mpe-image-resizer.mpe-image-resizer-e {
  bottom: 48%;
  right: -6px;
  cursor: e-resize;
}

.mpe-editor-shell .mpe-theme-image .mpe-image-resizer.mpe-image-resizer-se {
  bottom: -2px;
  right: -6px;
  cursor: nwse-resize;
}

.mpe-editor-shell .mpe-theme-image .mpe-image-resizer.mpe-image-resizer-s {
  bottom: -2px;
  left: 48%;
  cursor: s-resize;
}

.mpe-editor-shell .mpe-theme-image .mpe-image-resizer.mpe-image-resizer-sw {
  bottom: -2px;
  left: -6px;
  cursor: sw-resize;
}

.mpe-editor-shell .mpe-theme-image .mpe-image-resizer.mpe-image-resizer-w {
  bottom: 48%;
  left: -6px;
  cursor: w-resize;
}

.mpe-editor-shell .mpe-theme-image .mpe-image-resizer.mpe-image-resizer-nw {
  top: -6px;
  left: -6px;
  cursor: nw-resize;
}
