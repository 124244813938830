@tailwind base;
@tailwind components;
@tailwind utilities;

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* fix date picker render under modal*/
.MuiPickersPopper-root {
  z-index: 7000 !important;
}

li.react-datepicker__time-list-item--injected:not(
    .react-datepicker__time-list-item--selected
  ) {
  background-color: transparent !important;
  color: rgb(52, 55, 65) !important;
}

li.react-datepicker__time-list-item--injected:is(
    .react-datepicker__time-list-item--disabled
  ) {
  background-color: transparent !important;
  color: #abb4c4 !important;
}
