.mpe-link-editor {
  position: fixed;
  z-index: 2;
  background-color: white;
  width: 500px;
  box-shadow:
    0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
  border-radius: 8px;
  padding: 10px;
  border: 1px solid #e0e5ee;
}

/* .mpe-link-editor .mpe-button {
  text-align: center;
  width: 100px;
  height: 30px;
  border-radius: 8px;
  border-width: 0px;
  cursor: pointer;
} */

.mpe-link-editor > .mpe-error {
  color: red;
  font-size: 12px;
}
