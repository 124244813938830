.mpe-button__root {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border: 0px;
  background-color: #eee;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.mpe-button__root:hover {
  background-color: #ddd;
}

.mpe-button__small {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 13px;
}
.mpe-button__disabled {
  cursor: not-allowed;
  background-color: #eee;
  color: #afafaf;
}

.mpe-button__disabled:hover {
  background-color: #eee;
}
